import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignInRoutingModule } from './sign-in-routing.module';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { SftSwitchButtonModule } from 'src/stories/components/switch/switch.module';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { SftButtonModule } from 'src/stories/components/button/button.module';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    AngularMaterialModule,
    LoaderComponent,
    ReactiveFormsModule,
    SignInRoutingModule,
  ],
})
export class SignInModule {}
