export const noPageFound = [
  {
    heading: '',
    imgPath: '/images/404-page.png',
    alt: 'No Page Found',
    subtext: "Oops! Why you're here?",
    text: "We are very sorry for inconvenience. It looks like you're try to access a page that either has been deleted or never existed.",
    showButton: true,
    buttonTxt: 'Back to home',
    path: '',
  },
];
