<div class="sign-in py-7 px-12">
  <img src="/images/bg.svg" class="left-image" alt="background-img" />
  <img src="/images/bgright.svg" class="right-image" alt="background-img" />
  <h1 class="fw-700 mat-headline-4">SAMS</h1>
  <form action="/submit" [formGroup]="signInForm" (ngSubmit)="login()">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading" class="flex-center w-100 relative z-1">
      <div class="signin-wrapper p-8">
        <h2 class="mat-headline-5 fw-700 mb-2">SIGN IN</h2>
        <p class="mat-subtitle-2 fw-500 grey-text mb-6">
          Enter your email and password to sign in
        </p>
        <div class="input-wrapper mb-6">
          <div class="d-flex align-center mb-2">
            <p class="mat-body-1 mb-0 font-weight-500 input-label">
              Email <span class="required-star"> * </span>
            </p>
          </div>
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input
              matInput
              placeholder="Enter here..."
              type="email"
              formControlName="email"
              id="email"
              name="email"
              (keypress)="preventSpace($event)"
            />
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              signInForm.get('email')?.invalid &&
              signInForm.get('email')?.touched
            "
          >
            <span *ngIf="signInForm.get('email')?.errors?.['required']">
              Email is required.
            </span>
            <span *ngIf="signInForm.get('email')?.errors?.['email']">
              Email is not valid.
            </span>
          </span>
        </div>
        <div class="input-wrapper mb-6">
          <div class="d-flex align-center mb-2">
            <p class="mat-body-1 mb-0 font-weight-500 input-label">
              Password <span class="required-star"> * </span>
            </p>
          </div>
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              id="password"
              name="password"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="togglePasswordVisibility()"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              signInForm.get('password')?.invalid &&
              signInForm.get('password')?.touched
            "
          >
            Password is required.
          </span>
          <span class="required-star" *ngIf="errorMessage">{{
            errorMessage
          }}</span>
        </div>
        <div class="mb-6 login-button-wrapper">
          <sft-button
            [label]="'Login'"
            [color]="'primary'"
            [variant]="'flat'"
            [disable]="false"
            [icon]="'home'"
            [disableRipple]="false"
            [size]="'xl'"
            class="w-100"
            (buttonClick)="login()"
          ></sft-button>
        </div>
        <div class="d-flex align-center">
          <mat-icon class="mr-2">verified_user</mat-icon>
          <span class="grey-text mat-body-1">Anti-Robot Verification</span>
        </div>
      </div>
    </div>
  </form>
  <div class="d-flex justify-center align-end bottom-text absolute left-50">
    <span class="mr-3 mat-subtitle-2 grey-text fw-500">Powered by</span>
    <span class="d-flex"
      ><img src="/images/softobizlogo.svg" alt="logo"
    /></span>
  </div>
</div>
