import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { CustomerDashboardComponent } from './components/dashboard/dashboard.component';
import { CustomerLayoutModule } from '../../core/layout/layout.module';
import { CustomerDashboardRoutingModule } from './dashboard-routing.module';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { SftSwitchButtonModule } from 'src/stories/components/switch/switch.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { SharedModule } from 'src/shared/modules/shared/shared.module';

@NgModule({
  declarations: [CustomerDashboardComponent],
  imports: [
    CustomerLayoutModule,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    CustomerDashboardRoutingModule,
    FormsModule,
    AngularMaterialModule,
    SftInputModule,
    SftSwitchButtonModule,
    ReactiveFormsModule,
    LoaderComponent,
    SharedModule,
  ],
  exports: [CustomerDashboardComponent],
})
export class CustomerDashboardModule {}
