import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CostCenterModule } from './admin-portal/modules/cost-center/cost-center.module';
import { AccountModule } from './admin-portal/modules/account/account.module';
import { ExpenseModule } from './admin-portal/modules/expense-detail/expense.module';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { SharedModule } from '../shared/modules/shared/shared.module';
import { AngularMaterialModule } from '../shared/modules/angular-material/angular-material.module';
import { ProjectModule } from './admin-portal/modules/project/project.module';
import { ResourceModule } from './admin-portal/modules/resource/resource.module';
import { UserManagementModule } from './admin-portal/modules/user-management/user-management.module';
import { ProfileModule } from './admin-portal/modules/profile/profile.module';
import { DashboardModule } from './admin-portal/modules/dashboard/dashboard.module';
import { InvoicingModule } from './admin-portal/modules/invoicing/invoicing.module';
import { OpenPositionModule } from './admin-portal/modules/open-position/open-position.module';
import { TokenInterceptor } from './admin-portal/core/interceptors/token.interceptor';

// Customer Portal Imports
import { CustomerOpenPositionModule } from './customer-portal/modules/open-position/open-position.module';
import { CustomerDashboardModule } from './customer-portal/modules/dashboard/dashboard.module';
import { CustomerInvoicingModule } from './customer-portal/modules/invoicing/invoicing.module';
import { CustomerTeamManagementModule } from './customer-portal/modules/team-management/team-management.module';
import { SignInModule } from './sign-in/sign-in.module';
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    SignInModule,
    AppRoutingModule,
    DashboardModule,
    AccountModule,
    CostCenterModule,
    ProjectModule,
    ResourceModule,
    InvoicingModule,
    OpenPositionModule,
    ExpenseModule,
    SharedModule,
    AngularMaterialModule,
    UserManagementModule,
    ProfileModule,

    // Customer Portal

    CustomerDashboardModule,
    CustomerOpenPositionModule,
    CustomerInvoicingModule,
    CustomerTeamManagementModule,
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
