import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SignInService } from '../../services/sign-in.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent {
  signInForm!: FormGroup;
  errorMessage: string = '';
  hide: boolean = true;
  loading: boolean = false;
  userID: any;

  constructor(
    private formBuilder: FormBuilder,
    private signInService: SignInService,
    private globalService: GlobalService,
    private router: Router
  ) {}

  ngOnInit() {
    const isAuthenticated = this.globalService.isUserAuthenticated();
    if (isAuthenticated) {
      this.router.navigate(['/']);
    }
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  private getModule(roleData: any) {
    const modules: any[] = [];

    if (roleData && roleData.role && roleData.role.rolePermissions) {
      roleData.role.rolePermissions.forEach((permission: any) => {
        const isModuleExist = modules.find(
          (module: any) => module.module === permission.roleModule.name
        );
        if (isModuleExist) {
          isModuleExist.permission.push(permission.type);
        } else {
          modules.push({
            module: permission.roleModule.name,
            permission: [permission.type],
          });
        }
      });
    }

    return modules;
  }

  preventSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }

  login() {
    if (this.signInForm.invalid) {
      this.markFormGroupTouched(this.signInForm);
      return;
    }

    this.loading = true;
    const { email, password } = this.signInForm.value;
    this.signInService.login(email, password).subscribe({
      next: (response) => {
        if (response.data && response.data.token) {
          localStorage.setItem('session', JSON.stringify(response.data.token));
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem(
            'modules',
            JSON.stringify(this.getModule(response.data))
          );

          const user = JSON.parse(localStorage.getItem('user')!);
          const portalType = user.portolType;
          if (portalType === 'customer') {
            this.router.navigate(['/customer/dashboard']);
          } else if (portalType === 'admin') {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          console.error('Invalid response or missing token data.');
        }
        this.loading = false;
      },
      error: (error: any) => {
        console.error('Sign-in error:', error);
        if (error.status === 401) {
          this.errorMessage = 'Invalid email or password.';
        } else {
          this.errorMessage =
            error.error.message || 'An error occurred during sign-in.';
        }
        this.loading = false;
      },
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }
}
