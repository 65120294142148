import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private globalService: GlobalService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.globalService.checkTokenAndRedirect();

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const portalType = user.portolType;

    // Handle redirection based on portalType
    if (portalType === 'customer') {
      // Redirect customers trying to access admin routes
      if (
        state.url.startsWith('/account') ||
        state.url.startsWith('/cost-center') ||
        state.url.startsWith('/projects') ||
        state.url.startsWith('/resource') ||
        state.url.startsWith('/open-positions') ||
        state.url.startsWith('/invoicing') ||
        state.url.startsWith('/user-management')
      ) {
        this.router.navigate(['/customer/dashboard']);
        return false;
      }
    } else if (portalType === 'admin') {
      // Redirect admins trying to access customer routes
      if (state.url.startsWith('/customer')) {
        this.router.navigate(['/dashboard']);
        return false;
      }
    }

    return true; // Allow access to the requested route
  }
}
