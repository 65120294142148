import { Component } from '@angular/core';
import { noPageFound } from '../../constants/constant';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
  constructor() {}
  emptyData = noPageFound;
}
