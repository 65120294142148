import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../../shared/modules/angular-material/angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SftButtonModule } from '../../../../stories/components/button/button.module';
import { SftInputModule } from '../../../../stories/components/Input/input.module';
import { SftSwitchButtonModule } from '../../../../stories/components/switch/switch.module';
import { CommonModule } from '@angular/common';
import { StepperComponent } from '../../../../stories/components/stepper/stepper.component';
import { LayoutModule } from '../../core/layouts/layout/layout.module';
import { SftRadioButtonModule } from '../../../../stories/components/radiobutton/radio.module';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';

@NgModule({
  declarations: [],
  imports: [
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    SftRadioButtonModule,
    StepperComponent,
    CommonModule,
    SharedModule,
  ],
})
export class ExpenseModule {}
