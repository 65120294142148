import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './admin-portal/core/layouts/layout/layout.component';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { ManageRolePermissionComponent } from './admin-portal/modules/user-management/components/manage-role-permission/manage-role-permission.component';
import { CustomerLayoutComponent } from './customer-portal/core/layout/layout.component';
import { AuthGuard } from './sign-in/auth.guard';
import { SignInComponent } from './sign-in/components/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./admin-portal/modules/dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./admin-portal/modules/account/account.module').then(
            (mod) => mod.AccountModule
          ),
      },
      {
        path: 'cost-center',
        loadChildren: () =>
          import('./admin-portal/modules/cost-center/cost-center.module').then(
            (mod) => mod.CostCenterModule
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./admin-portal/modules/project/project.module').then(
            (mod) => mod.ProjectModule
          ),
      },
      {
        path: 'resource',
        loadChildren: () =>
          import('./admin-portal/modules/resource/resource.module').then(
            (mod) => mod.ResourceModule
          ),
      },
      {
        path: 'open-positions',
        loadChildren: () =>
          import(
            './admin-portal/modules/open-position/open-position.module'
          ).then((mod) => mod.OpenPositionModule),
      },
      {
        path: 'invoicing',
        loadChildren: () =>
          import('./admin-portal/modules/invoicing/invoicing.module').then(
            (mod) => mod.InvoicingModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import(
            './admin-portal/modules/user-management/user-management.module'
          ).then((mod) => mod.UserManagementModule),
      },
      {
        path: 'permission/manage',
        component: ManageRolePermissionComponent,
      },
      {
        path: 'permission/manage/:id',
        component: ManageRolePermissionComponent,
      },
      {
        path: 'profile/setting',
        loadChildren: () =>
          import('./admin-portal/modules/profile/profile.module').then(
            (mod) => mod.ProfileModule
          ),
      },
    ],
  },

  // Customer Portal Routing

  {
    path: '',
    component: CustomerLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customer/dashboard',
        loadChildren: () =>
          import(
            './customer-portal/modules/dashboard/dashboard-routing.module'
          ).then((mod) => mod.CustomerDashboardRoutingModule),
      },
      {
        path: 'customer/team-management',
        loadChildren: () =>
          import(
            './customer-portal/modules/team-management/team-management.module'
          ).then((mod) => mod.CustomerTeamManagementModule),
      },
      {
        path: 'customer/open-position',
        loadChildren: () =>
          import(
            './customer-portal/modules/open-position/open-position.module'
          ).then((mod) => mod.CustomerOpenPositionModule),
      },
      {
        path: 'customer/invoicing',
        loadChildren: () =>
          import('./customer-portal/modules/invoicing/invoicing.module').then(
            (mod) => mod.CustomerInvoicingModule
          ),
      },
      {
        path: 'customer/profile/setting',
        loadChildren: () =>
          import(
            './customer-portal/modules/customer-profile/profile.module'
          ).then((mod) => mod.CustomerProfileModule),
      },
    ],
  },

  // Page Not Found Route
  {
    path: '**',
    component: PageNotFoundComponent, // or any custom 404 component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
